@mixin bp($point) {
    $med: "(min-width: 768px)";
    $large: "(min-width: 1024px)";
    $small: "(max-width: 767px)";
    $mobile: "(max-width: 1023px)";
	$mini: "(max-width: 480px)";
	$giant: "(min-width: 1800px)";
	$xl: "(min-width: 1200px)";

    @if $point == med {
      @media all and #{$med} { @content; }
    }
    @if $point == lrg {
      @media all and #{$large} { @content; }
    }
    @if $point == sm {
    	@media all and #{$small} { @content; }
    }
    @if $point == mobile {
    	@media all and #{$mobile} { @content; }
    }
	@if $point == mini {
		@media all and #{$mini} { @content; }
	}
	@if $point == giant {
		@media all and #{$giant} { @content; }
	}
	@if $point == xl {
		@media all and #{$xl} { @content; }
	}
}