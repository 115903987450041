@import './mixins.scss';
@import './vars.scss';
@import './typography.scss';
@import './header.scss';
@import './footer.scss';
@import './player.scss';
@import './library.scss';

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

* img {
	max-width: 100%;
	max-height: 100%;
}

::selection {
	background-color: $secondary;
	color: $white;
}

html {
	font-size: 17px;
	-webkit-text-size-adjust: 100%;
}

html, body {
	max-width: 100%;
	overflow-x: hidden;
}

body {
	font-family: $body;
	font-weight: 400;
	line-height: 1.4;
	width: 100%;
	position: relative;
	margin: 0 auto;
	background: $white;
	letter-spacing: 0;

	main {
		position: relative;
		padding-bottom: 28px;

		&.maintenance {
			section {
				min-height: 100vh;
				background-color: $secondary;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.wrapper {
					width: 800px;
					max-width: 100%;
					text-align: center;
					margin-top: 10px;
					margin-bottom: 30px;

					.logo {
						width: 80px;
						margin: 0 auto 1rem;
					}

					h1, h2, p {
						color: $white;
						font-weight: 400;
						margin: 0 auto 0.5rem;
					}

					h1 {
						font-size: 2rem;
						@include bp(sm) {
							font-size: 1.8rem;
						}
					}

					h2 {
						font-size: 1.4rem;
					}

					a {
						font-weight: bold;
						color: $black;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		section {
			position: relative;
			padding: 40px 40px 0;
			@include bp(mini) {
				padding: 30px 20px 0;
			}

			&.video-player {
				@include bp(sm) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		
			.wrapper {
				max-width: 1000px;
				margin: 0 auto;

				.feature {
					max-width: 800px;
					margin: 0 auto;

					&.feature--center {
						> * {
							text-align: center;
						}

						p {
							max-width: 600px;
							margin-left: auto;
							margin-right: auto;
						}
					}

					&.feature--flex {
						display: flex;
						align-items: center;
						margin-top: 1.5rem;
						@include bp(sm) {
							display: block;
						}

						&.right {
							.content {
								margin-left: 30px;
							}
						}

						&.left {
							flex-direction: row-reverse;
							.content {
								margin-right: 30px;
							}
						}
						
						.image {
							flex-basis: 55%;
							@include bp(sm) {
								margin-bottom: 1.5rem;
							}

							img {
								display: block;
								margin: 0 auto;
							}
						}

						.content {
							flex-basis: calc(45% - 30px);
							@include bp(sm) {
								margin: 0 !important;
							}

							p, ul, ol {
								font-size: 15px;
								line-height: 22px;

								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

.flex {
	display: flex;
	margin: 30px -10px 0;
	flex-wrap: wrap;
	justify-content: center;
	@include bp(mini) {
		display: block;
	}

	.tile {
		margin: 0 10px 20px;
		flex-basis: 20%;
		font-size: 15px;
		line-height: 22px;
		text-align: center;
		flex-basis: calc(20% - 20px);
		@include bp(mobile) {
			flex-basis: calc(100% / 3 - 20px);
		}
		@include bp(sm) {
			flex-basis: calc(50% - 20px);
		}

		p {
			margin-bottom: 0;
		}
	}
}

.scrollTop {
	display: none;
	line-height: 1.2;
	font-size: 1rem;
	background-color: $primary;
	color: $white;
	padding: 12px 20px;
	opacity: 0.8;
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 100px;
	border: none;
	cursor: pointer;
	z-index: 100;

	&.visible {
		display: inline-block;
	}

	&.unfixed {
		position: absolute;
	}

	&:hover {
		opacity: 0.9;
	}
}