.library {
	.wrapper {
		.playlist {
			padding-bottom: 1.5rem;
			margin-bottom: 1.2rem;
			border-bottom: 1px solid $gray;

			&.callout {
				background-color: lighten($primary, 60%);
				border-bottom: none;
				padding: 1.5rem;
				border-radius: 4px;
			}

			h3 {
				font-size: 1.3rem;
				margin-bottom: 5px;
			}

			.slick-slider {
				.slick-list {
					padding: 10px 0;

					.slick-track {
						display: flex !important;

						.slick-slide {
							height: inherit !important;
							display: flex;
							@include bp(mini) {
								padding: 0 12px;
							}
						}
					}
				}

				.slick-prev, .slick-next {
					background-color: $secondary;
					border-radius: 100px;
					width: 24px;
					height: 24px;
					background-repeat: no-repeat;
					background-size: 12px 12px;
					z-index: 50;

					&.slick-disabled {
						display: none !important;
					}

					&:before {
						content: '';
					}

					&:hover {
						background-color: darken($secondary, 5%);
					}
				}

				.slick-prev {
					left: -22px;
					background-image: url('/images/angle-left-solid.svg');
					background-position: 5px 50%;
					@include bp(mini) {
						left: -6px;
					}
				}

				.slick-next {
					right: -22px;
					background-image: url('/images/angle-right-solid.svg');
					background-position: 7px 50%;
					@include bp(mini) {
						right: -6px;
					}
				}
			}

			.tiles {
				display: block;
				margin: 0 -10px;

				.tile {
					border: 1px solid $gray;
					background-color: $white;
					border-radius: 4px;
					cursor: pointer;
					margin: 0 10px;
					flex-shrink: 0;
					overflow: hidden;
					height: 100%;

					&.active {
						box-shadow: 0 0 8px $primary;
					}
					
					&:not(.active) {
						&:hover {
							box-shadow: 0 0 8px rgba(0,0,0,0.3);
						}
					}

					.image {
						img {
							width: 100%;
							display: block;
							margin: 0;
						}
					}

					.content {
						padding: 15px;

						h4 {
							margin: 0 0 4px;
						}
						
						p, ul {
							font-size: 0.9rem;
							margin-bottom: 4px;
						}
					}
				}
			}
		}
	}
}