* {
	font-family: $body;
}

h1 {
	font-weight: 400;
	font-size: 3rem;
	margin: 0 0 1rem;
	line-height: 1.2;
	color: $primary;

	@include bp(sm) {
		font-size: 2.2rem;
	}
}

h2 {
	font-weight: 400;
	font-size: 1.8rem;
	margin: 0 0 1rem;
	color: $primary;

	@include bp(sm) {
		font-size: 1.2rem;
	}
}

h3 {
	font-weight: 600;
	font-size: 1.2rem;
	margin: 0;
	color: $primary;

	@include bp(sm) {
		font-size: 1rem;
	}
}

p, ul, ol {
	margin: 0 0 1rem;
	color: $darkGray;
}

ol, ul {
	padding-left: 1rem;
}

a {
	text-decoration: none;
	font-weight: bold;
	color: $secondary;

	&:hover {
		color: darken($secondary, 5%);
	}

	&.cta {
		background-color: $secondary;
		color: $white;
		padding: 10px 20px;
		text-align: center;
		border: none;
		font-size: 0.9rem;
		border-radius: 3px;
		text-decoration: none;
		margin-bottom: 1rem;
		cursor: pointer;
	
		&:active, &:focus {
			border: none;
			outline: none;
		}
	
		&:hover {
			background-color: darken($secondary, 5%);
			color: $white;
		}

		&.cta--large {
			font-size: 1.1rem;
			padding: 15px 30px;
		}
	
		&.danger {
			background-color: $danger;
	
			&:hover {
				background-color: darken($danger, 5%);
			}
		}
	}
}

.no-wrap {
	white-space: nowrap;
	@include bp(mini) {
		white-space: normal;
	}
}