header {
	font-weight: 700;
	font-size: 0.9rem;
	padding: 20px 20px 0;
	text-align: center;
	background-color: $white;

	.logo {
		display: inline-block;
		max-width: calc(100% - 20px);

		a {
			display: block;
			width: 330px;
			max-width: 100%;
			height: 69px;
			text-indent: -9999px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			&.ss {
				background-image: url('/images/logo_lg-ss.svg');
			}

			&.pre {
				background-image: url('/images/logo_lg-pre.svg');
			}
		}
	}
}