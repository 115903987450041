footer {
	padding: 15px;
	background-color: darken($darkGray, 20%);

	nav {
		display: flex;
		justify-content: center;
		padding: 0 5px;

		@include bp(sm) {
			display: block;
			padding: 0;
		}

		a {
			display: block;
			margin: 0 15px;
			font-size: 0.85rem;
			font-weight: 400;
			color: $white;

			&:hover {
				color: lighten($secondary, 30%);
			}

			&:last-child {
				margin-bottom: 0;
			}

			@include bp(sm) {
				margin: 0 0 5px;
			}
		}
	}
}