.video-player {
	display: none;
	position: fixed;
	padding: 0;
	z-index: 100;
	width: 1200px;
	max-width: 100vw;
	top: 0;
	left: 50%;
	transform: translateX(-50%);

	&.fixed {
		display: block;
	}
	
	.wrapper {
		max-width: 1200px;
		
		.player-wrapper {
			position: relative;
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
			padding-top: 56.25%;
			padding-top: clamp(10px, 56.25%, calc(100vh - 40px));
			height: 0;

			.player-container {
				.close {
					display: block;
					box-sizing: content-box;
					border-radius: 5px;
					border: none;
					padding: 5px;
					height: 20px;
					width: 20px;
					background-color: rgba(30, 30, 30, 0.9);
					background-image: url('/images/xmark-solid.svg');
					background-size: 10px;
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					top: 10px;
					right: 10px;
					z-index: 101;
					cursor: pointer;

					&:hover {
						background-color: $primary;
					}
				}

				.react-player {
					position: absolute;
					background: $black;
					display: block;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			}
		}
	}
}